.api-map-container {
  flex-shrink: 0;
  position: sticky;
  top: 91px;
  width: 50vw;
  height: calc(100vh - 91px);
  overflow: hidden;
  transition: width 0.5s;
}

.api-map-container.expanded {
  width: 100% !important;
}

#api-mapboxgl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50vw;
  height: 100%;
}

#api-mapboxgl.full {
  width: 100vw;
}

#api-layout-toggle {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 10px 5px #00000005;
  z-index: 200;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  transition: opacity 0.2s;
}
.expanded #api-layout-toggle {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>');
}

#api-layout-toggle:hover {
  background-color: #f9f9f9;
}

.transition #api-layout-toggle {
  opacity: 0;
}

#api-layout-toggle-mobile {
  cursor: pointer;
  position: fixed;
  padding: 10px 20px;
  bottom: 20px;
  left: 50vw;
  transform: translateX(-50%);
  background-color: #ff9032;
  color: #fff;
  border-radius: calc(1rem + 20px);
  box-shadow: 0 7px 15px 5px #00000015;
  z-index: 200;
  user-select: none;
}

.marker > div {
  background-color: #fff;
  box-shadow: 0 2px 10px 5px #0000000f;
  border-radius: calc(1.01rem + 8px);
  padding: 4px 8px;
  letter-spacing: 1px;
  font-size: 1.01rem;
  font-weight: bold;
  border: 2px solid #fff;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.marker > div:hover,
.marker.hover > div {
  transform: scale(1.1);
}

.marker:hover,
.marker.hover,
.marker.open {
  z-index: 10000;
}

.marker.viewed > div {
  background-color: #e7e7e7;
  border-color: #d4d4d4;
}

.marker.open > div,
.marker.hover > div {
  color: #fff;
  background-color: black;
  border-color: black;
}

.mapboxgl-popup {
  box-shadow: 0 5px 15px 8px #00000026;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  background-color: #00000031;
  border-radius: 100%;
  color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>');
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
  outline: none;
  border: none;
}

.mapboxgl-popup-close-button:hover {
  background-color: #0000005e;
}
.mapboxgl-popup-content {
  padding: 0;
  max-height: 200px;
}

/* desktop */
@media only screen and (min-width: 1200px){
  #api-layout-toggle-mobile {
    display: none;
  }
}

/* mobile */
@media only screen and (max-width: 1199px){
  #api-layout-toggle {
    display: none;
  }
  #api-mapboxgl:not(.full) , .api-map-container:not(.expanded){
    width: 0;
  }
}

